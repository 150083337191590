* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-image: url(../assets/botnot.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
/* .background-customs {
   
  } */
@import url("https://fonts.googleapis.com/css2?family=Rock+3D&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");

.my-font {
  /* font-family: "Rock 3D", cursive; */
  font-family: "Poiret One", cursive;
}
.footer-custom {
  position: fixed;
  width: 100%;
  bottom: 0px;
  color: rgba(0, 0, 0, 0.76) !important;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;
}
.icon {
  width: 80px;
}
/*! FILE INPUT */

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: dashed 2px #22c55e;
  width: 300px;
  height: 150px;
  font-size: 14px;
  font-weight: 600;
  color: #22c55e;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #22c55e46;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

/*!FILE INPUT */
.heading {
  color: #22c55e;
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}
.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  font-size: 12px;
  margin-bottom: 10px;
}
.btn {
  outline: none;
  /* width: 150px; */
  height: 50px;
  border-radius: 20px;
  border: none;
  color: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #22c55e;
}
.glasss {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* border-radius: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.18);
}
